<template>
	<section class="container">
		<div>
			<h1>Opret medarbejder</h1>
		</div>
		<div class="row mt-2 mb-2">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row">
			<b-form @submit.prevent="onSubmit">
				<employeeInfo v-model="employeeInfo" :disabled="disabled"></employeeInfo>
				<div class="row mb-5 mt-3">
					<div class="col-12">
						<BaseActionButton class="btn-add" v-bind:clicked="clicked" v-bind:disabled="clicked" v-show="!disabled" type="submit" id="signupButton">
							{{ $t('global.create') }}
						</BaseActionButton>
					</div>
				</div>
			</b-form>
		</div>
	</section>
</template>

<script>
import { EmployeeInfo as EmployeeInfoModel } from '@/models/sub-models/employee/employeeInfo'
import EmployeeInfo from '@/modules/general/employee/components/EmployeeInfo.vue'

export default {
	name: 'CompanyEmployeeAdd',
	components: {
		EmployeeInfo,
	},
	async mounted() {
		console.log('Called')
		this.employeeInfo = new EmployeeInfoModel({})
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			clicked: false,
			disabled: false,
			employeeInfo: {},
		}
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			var data = {
				employeeInfo: this.employeeInfo,
			}
			try {
				console.log('Registering a employee', data)
				var result = await this.$store.dispatch('companyVuex/registerEmployee', {
					data: data,
					id: this.companyId,
				})
				var uid = result.uid
				var employeePolicyId = result?.policy?.id
				this.toast('Success', 'En medarbejder nu oprettet', true)
				this.$router.push({
					name: 'CompanyEmployeeInfo',
					params: { companyId: this.companyId, employeeId: uid, policyId: employeePolicyId },
				})
			} catch (err) {
				console.log(err)
				this.toast('Error', 'Noget gik galt' + err, false)
				this.clicked = false
			}
			this.clicked = false
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
	},
}
</script>

<style></style>
